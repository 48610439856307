import { useEffect, useState } from "react";
import { client } from "../../api/client";

const TestChargbee = () => {

    const [checkout, setCheckout] = useState();

    useEffect(() => {
        // client.get('chargebee/generate/rs5rTfFGCopPLEgv').then(response => {
        //     console.log('response', response.session);
        //     setCheckout(response.session);
        // });
    }, []);

    return (
        <div>
            <Checkout checkout={checkout} />
            {/* ululululululu
            <button class="button is-primary is-subscribe"
                data-cb-type="checkout"
                data-cb-plan-id="P-6YG15251L4455140KMFBMHTA"
                data-cb-plan-quantity="1">SUBSCRIBE</button>
            
            <script src="https://js.chargebee.com/v2/chargebee.js" data-cb-site="tempobot-test"></script> */}

        </div>
    )
};

const urlEncode = function(data) {
  var str = [];
  for (var p in data) {
      if (data.hasOwnProperty(p) && (!(data[p] == undefined || data[p] == null))) {
          str.push(encodeURIComponent(p) + "=" + (data[p] ? encodeURIComponent(data[p]) : ""));
      }
  }
  return str.join("&");
}

const Checkout = ({ checkout }) => {

    const [open, setOpen] = useState(false);

    const [chargebee, setChargebee] = useState(null);
    const [cart, setCart] = useState(null);

    useEffect(() => {
        window.Chargebee.init({
            site: "tempobot-test"
        });

        let cbInstance = window.Chargebee.getInstance();
        cbInstance.setPortalSession(() => {
            return client.get(`chargebee/session`).then(response => response.session);
        });

        setChargebee(cbInstance);
    }, []);

    const handleCheckout = () => {
        chargebee.openCheckout({
            hostedPage() {
                return client.get(`chargebee/checkout`).then(response => response.hosted_page);
            },
            success(hostedPageId) {
                client.post(`chargebee/success`, { hosted_page_id: hostedPageId }).then(response => {
                    console.log('checkout done!'); 
                });
                console.log('hosted page id', hostedPageId);  
            },
            close() {
                console.log('checkout closed');
            }
        });
    };

    const handleCheckoutExisting = () => {

    };

    const handlePortal = () => {
        chargebee.createChargebeePortal().open({
            visit(visit) {
                console.log('portal visit', visit);
            }
        });
    };

    const openCheckout = () => {
        cart.proceedToCheckout();
    };

    return (
        <div>
            <button className="button is-primary" onClick={() => handleCheckout()}>Checkout</button>
            <button className="button is-primary" onClick={() => handlePortal()}>Portal</button>
=
            {/* <div id="modal-id" class={"modal modal-fx-fadeInScale " + (open ? 'is-active' : '') }>
                <div class="modal-background" onClick={() => setOpen(false) }></div>
                <div class="modal-content" style={{width: '100vw', height: '1200px', overflow: 'auto', zIndex: '500'}}>
                    {/* <iframe src={checkout}></iframe> */}
                    {/* <iframe src={checkout} id="purchase-iframe" style={{width: '100vw', height: '1200px', position: 'relative', border: '0 none', zIndex: '500', display: 'block'}} scrolling="no" title="Checkout Page"></iframe>

                    <button class="delete is-large checkout-close" onClick={() => setOpen(false) }></button>
                </div>
            </div>
            <button className="button is-primary" onClick={() => setOpen(true)}>
                Checkout
            </button> */} 
        </div>
    );

};

export default TestChargbee;