import { useEffect, useState } from "react";

const SaveBox = (props) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(function(){
            setLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        if(loading)
            return;
        document.getElementById("save-box").classList = "noselect save-box-container " + (props.pristine ? 'hide' : 'show');
    }, [props.pristine]);

    return(
        <div>
            <div className={"noselect save-box-container"} id="save-box">
                <div className="columns is-centered is-gapless">
                    <div className="column is-narrow">
                        <div style={{  width: '300px', height: '5px' }}>&nbsp;</div>
                    </div>
                    <div className="column">
                        <div className="columns is-centered is-mobile">
                            <div className="column is-8">
                                <div className="box save-box">
                                    
                                    <div className="columns is-vcentered is-mobile">
                                        <div className="column">
                                            <p>You have unsaved changes!</p>
                                        </div>
                                        <div className="column is-narrow">
                                            <button className="button is-link-button" onClick={ () => props.resetModule() }>Cancel</button>
                                            <button className={"button is-primary" + (props.saving ? " is-loading" : '') } id="save-button" onClick={ () => props.saveModule() }>
                                                <span>Save</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SaveBox;