import { useEffect, useRef, useState } from "react";
import { act } from "react-dom/test-utils";
import { client } from "./api/client";
import SegmentedControl from "./panel/components/SegmentedControl";

import "../css/premium.css";
import {CSSTransition} from "react-transition-group";
import ButtonControl from "./panel/components/ButtonControl";

const prices = {
    monthly: { one: '4.99', three: '7.99', ten: '15.99' },
    yearly: { one: '49.99', three: '79.99', ten: '159.99' },
    lifetime: { one: '79.99', three: '119.99', ten: '199.99'}
};

const planIds = {
    monthly: { one: '', three: '', ten: '' },
    yearly: { one: '', three: '', ten: '' }
};


const PremiumPage = () => {

    const [length, setLength] = useState('monthly');
    const [activePlan, setActivePlan] = useState('one');

    const openCheckout = () => {
        document.getElementById("checkout-modal").classList += ' is-active';
    };

    const [checkoutActive, setCheckoutActive] = useState(false);

    return (
        <div>
            <br /> <br />
            <div className="container">
                <div className="premium-content">
                    <center>
                        <div className="module-title premium-title">
                            Get the ultimate experience with <span>Tempo Premium</span>
                        </div>
                    </center>
                    <div className="columns is-vcentered">
                        <div className="column">
                            <div className="select-a-plan">Select your premium plan</div>
                        </div>
                        <div className="column is-narrow">
                            {/*<SegmentedControl*/}
                            {/*    name="premium-length"*/}
                            {/*    callback={(length) => { setLength(length) }}*/}
                            {/*    segments={[*/}
                            {/*        { label: 'Monthly', value: 'monthly', ref: useRef() },*/}
                            {/*        { label: 'Yearly', value: 'yearly', ref: useRef() },*/}
                            {/*    ]}*/}
                            {/*    classList="is-box-theme premium" />*/}
                            <ButtonControl
                                classList={"premium"}
                                buttons={[
                                    { label: 'Monthly', value: 'monthly' },
                                    { label: 'Yearly', value: 'yearly' },
                                    { label: 'Lifetime', value: 'lifetime' },
                                ]}
                                handle={(length) => setLength(length)}/>
                        </div>
                    </div>
                </div>


                <br />

                <div className="columns is-vcentered is-centered">
                    <div className="column is-narrow">
                        <PlanCard
                            length={length}
                            serverText="1 server"
                            servers={'one'}
                            setActivePlan={setActivePlan}
                            activePlan={activePlan} />
                    </div>
                    <div className="column is-narrow">
                        <PlanCard
                            length={length}
                            serverText="3 servers"
                            servers={'three'}
                            setActivePlan={setActivePlan}
                            activePlan={activePlan} />
                    </div>
                    <div className="column is-narrow">
                        <PlanCard
                            length={length}
                            serverText="10 servers"
                            servers={'ten'}
                            setActivePlan={setActivePlan}
                            activePlan={activePlan} />
                    </div>
                </div>

                <br />

                <Checkout checkoutActive={checkoutActive} setCheckoutActive={setCheckoutActive} length={length} activePlan={activePlan} />
                <CheckoutModal active={checkoutActive} setActive={setCheckoutActive} length={length} activePlan={activePlan} />
                <button onClick={() => openCheckout()}>open checkout</button>

                <br />
            </div>

            <br />

            <center>
                <div className="module-title">
                    What do I get for supporting Tempo?
                </div>
            </center>

            <br /><br />
            <div className="columns is-centered">
                <div className="column is-narrow">
                    <PremiumInfo
                        title={"Take control of your<br/>music"}
                        description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                        icon={"tune"} />
                </div>
                <div className="column is-narrow">
                    <PremiumInfo
                        title={"Sing along with your favourite songs"}
                        description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                        icon={"lyrics"} />
                </div>
                <div className="column is-narrow">
                    <PremiumInfo
                        title={"Get issues resolved quicker with our Premium support"}
                        description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                        icon={"support_agent"} />
                </div>
            </div>
            <div className="columns is-centered">
                <div className="column is-narrow">
                    <PremiumInfo
                        title={"6 additional bots for more tunes"}
                        description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                        icon={"group_add"} />
                </div>
                <div className="column is-narrow">
                    <PremiumInfo
                        title={"Keep the party going with 24/7 playback"}
                        description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                        icon={"more_time"} />
                </div>
                <div className="column is-narrow">
                    <PremiumInfo
                        title={"Find new music with autoplay suggestions"}
                        description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                        icon={"dynamic_form"} />
                </div>
            </div>
            <br />

            <PremiumTable />

            <br /> <br />
        </div>
    );
};

const PremiumInfo = ({title, description, icon}) => {
    return(
        <div className="premium-info-box">
            <div className="columns is-mobile is-vcentered is-variable is-2">
                <div className="column is-narrow">
                    <div className="icon-box">
                        <span className="material-symbols-rounded">
                            {icon}
                        </span>
                    </div>
                </div>
                <div className="column">
                    <div className="info-title" dangerouslySetInnerHTML={{__html:title}}></div>
                </div>
            </div>
            <div className="info-description" dangerouslySetInnerHTML={{__html:description}}></div>
        </div>
    )
};

const Cross = () => {
    return (
        <div>
            <center>
                <span className="material-icons-round">
                    &#xe5c9;
                </span>
            </center>
        </div>
    )
};

const Tick = ({ premium }) => {
    return (
        <div>
            <center>
                <span className={`material-icons-round ${premium ? 'premium' : ''}`}>
                    &#xe86c;
                </span>
            </center>
        </div>
    )  
};

const PlanCard = ({ length, serverText, servers, setActivePlan, activePlan }) => {
    return (
        <div>
            <div className={`purchase-card ${activePlan === servers ? 'active-plan' : ''}`} onClick={() => setActivePlan(servers)}>
                <div className="stars">
                    {servers === 'one' ?
                        <div>
                            <span className="material-icons-round">&#xe838;</span>
                        </div> : <div />}
                    {servers === 'three' ?
                        <div>
                            <span className="material-icons-round">&#xe838;</span>
                            <span className="material-icons-round">&#xe838;</span>
                        </div> : <div />}
                    {servers === 'ten' ?
                        <div>
                            <span className="material-icons-round">&#xe838;</span>
                            <span className="material-icons-round">&#xe838;</span>
                            <span className="material-icons-round">&#xe838;</span>
                        </div> : <div />}
                </div>

                <div className="server-count">
                    {serverText}
                </div>

                <div className="server-desc">
                    Upgrade {serverText} to Tempo Premium
                </div>

                <div className="plan-price">
                    <span className="plan-dollar">
                        ${ prices[length][servers] }&nbsp;
                    </span>
                    <span className="plan-sub">
                        { length === 'monthly' ? '/ month' : '' }{ length === 'yearly' ? '/ year' : '' }
                    </span>
                </div>

                <CSSTransition in={activePlan === servers} timeout={250} classNames={"application-loader"} unmountOnExit>
                    <div>
                        <span className="material-icons-round tick">
                            check
                        </span>
                    </div>
                </CSSTransition>
            </div>
        </div>
    )  
};

const Checkout = ({ length, activePlan, setCheckoutActive }) => {

    return (
        <div>
            {/* <button className="button is-primary" onClick={() => handleCheckout()}>Checkout</button>
            <button className="button is-primary" onClick={() => handlePortal()}>Portal</button> */}
            <hr className="premium" />

            <div className="total-box">
                <div className="columns is-vcentered is-mobile">
                    <div className="column">
                        <div className="price-total">
                            Total ${ prices[length][activePlan] }
                        </div>
                    </div>
                    <div className="column is-narrow">
                        <button class="button is-premium" onClick={() => setCheckoutActive(true)}>Checkout</button>
                    </div>
                </div>
            </div>

            <hr className="premium" />
        </div>
    )

};

const CheckoutModal = ({ active, setActive, activePlan, length, activeCoupon, setActiveCoupon }) => {
    const [stage, setStage] = useState('ONE');

    return (
        <div id="checkout-modal" className={`modal modal-fx-fadeInScale ${active ? 'is-active' : ''} ${stage === 'ONE' ? 'stage-one' : ''}`}>
            <div class="modal-background" onClick={() => setActive(false)}></div>
            <div class="modal-content">
                <div className="content-card">
                    <CheckoutStageOne
                        stage={stage}
                        activePlan={activePlan}
                        length={length}
                        setActive={setActive} />
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close"></button>
        </div>
    )
};


const CheckoutStageOne = ({ stage, activePlan, length, setActive }) => {

    if (stage !== 'ONE')
        return null;
    
    return (
        <div className="checkout-stage-one">
            <div className="checkout-title">
                Checkout
            </div>

            <div className="box premium-details">
                <div className="columns is-vcentered is-mobile">
                    <div className="column is-narrow">
                        <img src="https://tempobot.net/images/bots/tempo.webp" alt="Tempo Logo" />
                    </div>
                    <div className="column is-narrow">
                        <div className="premium-text">Tempo Premium</div>
                        <div className="premium-subtext">{activePlan} {`server${activePlan !== "one" ? 's' : ''}`}</div>
                    </div>
                </div>
            </div>

            <div className="box premium-details">
                <div className="columns is-vcentered is-mobile">
                    <div className="column" style={{color: 'rgba(255,255,255,.8)'}}>
                        {length == 'monthly' ? 'Monthly' : ''}
                        {length == 'yearly' ? 'Yearly' : ''}
                        {length == 'lifetime' ? 'One time' : ''}
                    </div>
                    <div className="column is-narrow">
                        <span className="gold">$4.49</span>
                    </div>
                </div>
            </div>

            <ChargebeeButton length={length} setActive={setActive} />
        </div>
    )
};

const ChargebeeButton = ({length, setActive}) => {

    const [open, setOpen] = useState(false);

    const [chargebee, setChargebee] = useState(null);
    const [cart, setCart] = useState(null);

    useEffect(() => {
        window.Chargebee.init({
            site: 'tempobot-test'
        });

        let cbInstance = window.Chargebee.getInstance();
        cbInstance.setPortalSession(() => {
            return client.get(`chargebee/session`).then(response => response.session);
        });

        setChargebee(cbInstance);
    }, []);

    const handleCheckout = () => {
        chargebee.openCheckout({
            hostedPage() {
                setActive(false);
                return client.post(`chargebee/checkout`, { planId: length }).then(response => response.hosted_page);
            },
            success(hostedPageId) {
                client.post(`chargebee/success`, { hosted_page_id: hostedPageId }).then(response => {
                    console.log('checkout done!');
                });
                console.log('hosted page id', hostedPageId);
            },
            close() {
                console.log('checkout closed');
            }
        });
    };

    const handleCheckoutExisting = () => {

    };

    const handlePortal = () => {
        chargebee.createChargebeePortal().open({
            visit(visit) {
                console.log('portal visit', visit);
            }
        });
    };

    const openCheckout = () => {
        cart.proceedToCheckout();
    };

    return(
        <div className="button chargebee-button" onClick={() => handleCheckout()}>
            Checkout by Card
        </div>
    )
};



const PremiumTable = () => {
    return(
        <div className="columns is-centered">
            <div className="column is-7 is-tablet-11">

                <div className="columns is-mobile is-variable is-1">
                    <div className="column">
                        <div className="perkbox">
                            <div className="header">feature</div>

                            <div className="list-items">
                                <div className="list-item">Audio Controls</div>
                                <div className="list-item">Lyrics</div>
                                <div className="list-item">Premium Support</div>
                                <div className="list-item">Volume Controls</div>
                                <div className="list-item">Preset Volume</div>
                                <div className="list-item">6 Additional Bots</div>
                                <div className="list-item">24/7 Audio</div>
                                <div className="list-item">Audio Filters</div>
                                <div className="list-item">Autoplay</div>
                                <div className="list-item">Playlists</div>
                                <div className="list-item">Enhanced Queue Controls</div>
                                <div className="list-item">Queue Duplicate Prevention</div>
                                <div className="list-item">Auto Rejoin</div>
                                <div className="list-item">Auto Cleanup</div>
                                <div className="list-item">Premium Role in our support server</div>
                                <div className="list-item">Beta Access</div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-3 is-4-mobile">
                        <div className="perkbox standard">
                            <div className="header">standard</div>

                            <div className="list-items">
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><p className="item">VOTE LOCKED</p></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                                <div className="list-item"><Cross /></div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-3 is-4-mobile">
                        <div className="perkbox premium-perks">
                            <div className="header">premium</div>

                            <div className="list-items">
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                                <div className="list-item"><Tick /></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default PremiumPage;
