import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { userFetched } from "../features/user/userSlice";

const localStorageKey = '__TEMPO_TOKEN__';

export const LoginButton = (props) => {

    const [loading, setLoading] = useState(0);

    const user = useSelector(state => state.user).user;

    const dispatch = useDispatch();

    const history = useHistory();

    const login = () => {
        setLoading(true);

        const login = window.open(
            process.env.REACT_APP_AUTH_URL + "login", "SignIn",
            "width=600,height=900,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=" + 500 + ",top=" + 200
        );

        var checkClosed = setInterval(function(){
            if(login.closed){
                setLoading(false);
                clearInterval(checkClosed);
            }
        }, 500);

        window.addEventListener('message', function(event){
            // UNCOMMENT FOR PRODUCTION 
            // if(process.env.REACT_APP_ENV !== 'dev' && event.origin !== process.env.REACT_APP_SITE_URL)
            //     return;
            if(!((typeof event.data === 'string' || event.data instanceof String)))
                return;
    
            const data = JSON.parse(event.data);
    
            if(this.window.localStorage.getItem(localStorageKey) !== null)
                this.window.localStorage.removeItem(localStorageKey);
            this.window.localStorage.setItem(localStorageKey, data.user.api_token);

            delete data.user.api_token;

            dispatch(userFetched(data));

            history.push('/');
        });
    };

    return (
        <div>
            <button className={ props.classes + (loading ? ' is-loading' : '') } onClick={ () => login() }>
                { props.text }
            </button>
        </div>
    )
}