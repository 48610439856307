import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const localStorageKey = '__TEMPO_TOKEN__';

function TempoAdminRoute({ element, path, authenticated = false, component = null, render = null, roles = null, ...props}){
    let ele = element;

    if(window.localStorage.getItem(localStorageKey) === null)
        return (<div><Redirect to="/login" /></div>);

    if(roles != null && !roles.includes('developer'))
        return (<div><Redirect to="/" /></div>);

    if(render === null)
        return <Route exact path={path} component={component === null ? ele : component} {...props} />;
    return <Route exact path={path} render={(props) => render} {...props} />;
};

export default TempoAdminRoute;

