import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UserProfile } from "../../features/user/UserProfile";

const AdminNavbar = ({ container }) => {


    const user = useSelector(state => state.user).user;

    return(
        <div>
            <nav className="navbar panel-navigation noselect" role="navigation" aria-label="Tempo Navigation">
                <div className={'container'}>
                    <div className="navbar-brand">
                        <div className="navbar-item">
                            <div id="navbar-logo" className="tempo-logo" />
                        </div>

                        <div role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="tempo-navbar">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </div>
                    </div>

                    <div id="tempo-navbar" className="navbar-menu">
                        <div className="navbar-start">
                            <Link className="navbar-item text-link" to="/">
                                Home
                            </Link>
                            <a className="navbar-item text-link" href="https://google.com">
                                Commands
                            </a>
                            <a className="navbar-item text-link" href="https://google.com">
                                Support
                            </a>
                            <a className="navbar-item text-link" href="https://google.com">
                                Invite Tempo
                            </a>
                        </div>

                        <div className="navbar-end">
                            <UserProfile user={user} />
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default AdminNavbar;