import {useState} from "react";

const ButtonControl = ({ classList = null, buttons, handle = null, defaultButton = null }) => {

    const [activeButton, setActiveButton] = useState(defaultButton === null ? buttons[0].value : defaultButton);

    const changeButton = (button) => {
        setActiveButton(button);
        if(handle !== null)
            handle(button);
    };

    return(
        <div className={'button-control' + (classList !== null ? (' ' + classList) : '')}>
            <div className="columns is-mobile is-centered is-vcentered is-variable is-1">
                {buttons.map((button) => {
                    return(
                        <div className="column is-narrow">
                            <div className={"button" + (activeButton === button.value ? " active" : "")} onClick={() => changeButton(button.value)}>
                                {button.label}
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    )
};

export default ButtonControl;