import { useSelector } from "react-redux";
import { BrowserRouter, Link, Route , Switch} from "react-router-dom";
import { UserProfile } from "../../features/user/UserProfile";
import {useState} from "react";
import {CSSTransition} from "react-transition-group";

const Navbar = (props) => {
    
    const user = useSelector(state => state.user).user;

    return (
        <div>
            <Desktop user={user} />
            <Mobile user={user} />
        </div>
    );
};

const Mobile = ({ user }) => {

    const [open, setOpen] = useState(false);

    return(
        <div className="mobile-navigation">
            <div className="nav">
                <div className="columns is-vcentered is-mobile">
                    <div className="column">
                        <div id="nav-icon2" className={open ? "open" : ""} onClick={() => setOpen(!open)}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="column is-narrow">
                        <center>
                            <Link to="/" className="tempo-logo" onClick={() => setOpen(false)}>
                                TEMPO
                            </Link>
                        </center>
                    </div>
                    <div className="column">
                        <div className="right">
                            <img alt="User Avatar" src={ user.avatar + "?size=64" } className="user-icon" />
                        </div>
                    </div>
                </div>
            </div>
            <CSSTransition in={open} timeout={250} classNames={"left-to-right"} unmountOnExit>
                <div className="full-nav">
                    <Link to={"/commands"} className={"mobile-link"} onClick={() => setOpen(false)}>
                        Commands
                    </Link>
                    <Link to={"/premium"} className={"mobile-link"} onClick={() => setOpen(false)}>
                        Premium
                    </Link>
                    <Link to={"/docs"} className={"mobile-link"} onClick={() => setOpen(false)}>
                        Guide
                    </Link>
                    <Link to={"/faqs"} className={"mobile-link"} onClick={() => setOpen(false)}>
                        FAQs
                    </Link>
                    <a href={"https://discord.gg/tempobot"} className={"mobile-link"}>
                        Get Support
                    </a>
                </div>
            </CSSTransition>
        </div>
    )
};

const Desktop = ({ user }) => {
    return(
        <div className="navigation noselect desktop-navigation">
            <nav className="navbar site-navigation is-fixed-top" role="navigation" aria-label="main navigation">
                <div className="container">
                    <div className="navbar-brand">
                        <Link className="navbar-item logo" to="/">
                            <div className="text" data-text="TEMPO">TEMPO</div>
                        </Link>
                        {/* <div className="navbar-item logo">
                                <div id="navbar-logo" className="tempo-logo" />
                            </div> */}

                        <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false"
                           data-target="tempo-navbar">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>

                    <div id="navbar" className="navbar-menu">
                        <div className="navbar-start">
                        </div>

                        <div className="navbar-end">
                            <Link className="navbar-item text-link" to="/commands">
                                Commands
                            </Link>

                            <Link to="/premium" className="navbar-item text-link">
                                Premium
                            </Link>

                            {process.env.REACT_APP_ENV === 'dev' ?
                                <Link className="navbar-item text-link" to="/docs">
                                    Guide
                                </Link> : <div></div>}

                            <a className="navbar-item text-link" href="/faq">
                                FAQs
                            </a>

                            <a className="navbar-item text-link" href="https://discord.gg/tempobot">
                                Get Support
                            </a>

                            <UserProfile user={user}/>
                            {/* TODO: Notifications */}
                        </div>

                    </div>
                </div>
            </nav>
        </div>
    )
};

export default Navbar;