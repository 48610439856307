import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    emojis: []
};

const emojisSlice = createSlice({
    name: 'emojiList',
    initialState,
    reducers: {
        emojisFetched(state, action) {
            state.emojis = action.payload;
        }
    }
});

export const { emojisFetched } = emojisSlice.actions;

export default emojisSlice.reducer;