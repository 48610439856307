import { useEffect, useState } from "react";
import { client } from "../../api/client";
import {CSSTransition} from "react-transition-group";
import { useSpring, animated } from "react-spring";
const Commands = () => {

    const [commands, setCommands] = useState([]);
    const [modules, setModules] = useState([]);
    const [activeModule, setActiveModule] = useState('ALL');

    const [commandSearch, setCommandSearch] = useState('');

    const commandResult = commands.filter(
        command => {
            return (
                (activeModule === 'SEARCH' && command.command.includes(commandSearch)) || 
                (activeModule === 'PREMIUM' && command.premium) ||
                activeModule === 'ALL' ||
                command.module === activeModule
            );
    });

    useEffect(() => {
        client.get('site/commands').then(response => {
            console.log(response.commands);
            setCommands(response.commands);
            setModules(response.modules);
        });
    }, []);

    return (
        <div className="module container">
            <br />

            <center>
                <div className="module-title">
                    Commands
                </div>
            </center>

            <br />

            <div className="columns is-variable is-2">
                <div className="column is-narrow">
                    <div className="content-card commands-sidebar noselect">
                        <CommandSearch
                            setActiveModule={setActiveModule}
                            setCommandSearch={setCommandSearch}
                            active={activeModule === 'SEARCH'} />
                        
                        <div className={`sidebar-link ${activeModule === 'ALL' ? 'is-active' : ''}`} onClick={() => setActiveModule('ALL')}>
                            All
                        </div>

                        {modules.map((module) => {
                            return (
                                <div className={`sidebar-link ${activeModule === module ? 'is-active' : ''}`} onClick={() => setActiveModule(module)} key={module}>
                                    <div style={{ textTransform: 'capitalize' }}>
                                        {module}
                                    </div>
                                </div>
                            )
                        })}

                        <div className={`sidebar-link ${activeModule === 'PREMIUM' ? 'is-active' : ''}`} onClick={() => setActiveModule('PREMIUM')}>
                            Premium
                        </div>
                    </div>
                </div>
                <div className="column">
                    <CommandList commandResult={commandResult} />
                </div>
            </div>

        </div>
    )
};

const CommandSearch = ({ setActiveModule, setCommandSearch, active }) => {
    
    const [search, setSearch] = useState('');

    const activateSearch = () => {
        setActiveModule('SEARCH');
        setCommandSearch(search);
    }

    const searchChanged = (search) => {
        setSearch(search);
        setCommandSearch(search);
    }

    return (
        <div>
            <div className={`sidebar-link search ${active ? 'is-active' : ''}`} onClick={() => activateSearch()}>
                <p class="control has-icons-left has-icons-right">
                    <input type="text" class="search-box" placeholder="Search" value={search} onChange={(e) => searchChanged(e.target.value)} />
                    <span class="icon is-small is-right">
                        <span className="material-icons-round">
                            &#xe8b6;
                        </span>
                    </span>
                </p>
            </div>
        </div>
    )

};

const CommandList = ({ commandResult }) => {

    const [open, setOpen] = useState(false);
    const [close, setClose] = useState(true);


    const toggleAll = () => {
        if(open){
            setOpen(false);
            setClose(true);
        }else if(close){
            setOpen(true);
            setClose(false);
        }
    };

    return (
        <div>
            <div className="columns is-variable is-1">
                <div className="column">
                    <div className="commands">
                        {commandResult.map((command) => {
                            return (
                                <Command command={command} open={open} close={close} />
                            )
                        })}
                    </div>
                </div>
                <div className="column is-narrow">
                    <div className="expand-button" onClick={() => toggleAll()}>
                    <span className="material-symbols-rounded">
                        {close ? 'unfold_more' : 'unfold_less'}
                    </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

const Command = ({ command, reset, closeAll, open, close }) => {

    const [active, setActive] = useState(false);

    useEffect(() => {
        console.log('command', command);
    }, [command]);

    useEffect(() => {
        setActive(reset === command.command_id)
    }, [reset]);

    useEffect(() => {
        if(open)
            setActive(true);
    }, [open]);

    useEffect(() => {
        if(close)
            setActive(false);
    }, [close]);

    const openCommand = () => {
        setActive(!active);
        // closeAll(command.command_id);
    };

    const openAnimation = useSpring({
        from: { opacity: "0", maxHeight: "45px" },
        to: { opacity: "1", maxHeight: active ? "120px" : "45px" },
        config: { duration: "300" }
    });

    return(
        <animated.div className={`command${active ? ' active' : ''}`} onClick={() => openCommand()} style={openAnimation}>
            <div className="columns is-mobile is-variable is-1">
                {command.premium ?
                    <div className="column is-narrow">
                        <div className="star-box">
                     <span className="material-icons-round">
                        star
                     </span>
                        </div>
                    </div> :
                    <div className="column is-narrow">
                        <div className="slash-box">
                            /
                        </div>
                    </div>
                }
                <div className="column is-narrow">
                    <div className="command-trigger">
                        {command.command}
                    </div>
                </div>
                <div className="column">
                    <div className="command-arguments">
                        {command.arguments}
                    </div>
                </div>
                <div className="column is-narrow">
            <span className="material-symbols-rounded">
                expand_more
            </span>
                </div>
            </div>
            <div className="command-info">
                <div className="command-description">
                    {command.description}
                </div>
            </div>
        </animated.div>
    )
};

export default Commands;