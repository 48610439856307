import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { client } from "../../api/client";
import { guildsFetched } from "../../features/guild/guildsSlice";

const ServerListLoader = () => {
    return (
        // <div className="columns is-centered is-multiline">
        <div>
            { Array.from(Array(6), (e, i) => {
                return(<div className="server-list-loader">
                    <div className="columns is-vcentered is-variable is-1">
                        <div className="column is-narrow">
                            <div className="loader-icon-box">
                                <Skeleton width={60} height={60} circle={true} className="icon-image" />
                            </div>
                        </div>
                        <div className="column">
                            <Skeleton width={163} height={30} />
                            <br />
                            <Skeleton width={75} height={20} className="server-name-loader" />
                        </div>
                        <div className="column is-narrow">
                            <Skeleton width={150} height={40} className="server-name-loader"></Skeleton>
                        </div>
                    </div>
                </div>)
            })}
        </div>
    )
}

const ServerList = () => {

    const [loading, setLoading] = useState(1);
    const [tester, setTester] = useState(false);
    const [search, setSearch] = useState('');

    const history = useHistory();

    const guilds = useSelector(state => state.guildList);
    const user = useSelector(state => state.user);

    const dispatch = useDispatch();

    const searchResults = guilds.guilds.filter(
        guild => {
            return(
                guild.name.toLowerCase().includes(search) ||
                guild.id.includes(search)
            )
        }
    );

    useEffect(() => {
        if(user.user === false || user.authenticated === false)
            return <Redirect to="/login" />;

        // KICK OUT ANY NON-TESTERS USERS
        if(user.user.roles.includes("developer") ||
            user.user.roles.includes('support') ||
            user.user.roles.includes('tester')){
                setTester(true);
            return;
        }
    }, [user]);

    const loadServers = () => {
        client.get('guild').then(response => {
            let user_guilds = response.guilds.sort((a,b) => b.available - a.available);
            user_guilds = user_guilds.sort((a,b) => b.premium - a.premium);
            user_guilds = user_guilds.sort((a,b) => b.support_session - a.support_session);

            dispatch(guildsFetched(user_guilds));
        });
    };

    useEffect(() => {
        loadServers();
    }, []);

    useEffect(() => {
        if(guilds.guilds.length === 0)
            return;
        
        setTimeout(function(){
            setLoading(false);
        }, 2000);
    }, [guilds]);

    if(!tester && (user.user === false || user.authenticated === false)){
        return(
            <div>
                <div className="server-list">
                    <div className="page-title">You can't access the dashboard yet!</div>
                    <center>
                        <div className="page-subtitle">The new Dashboard is only available to staff and the Tester Program.</div>
                    </center>
                </div>
            </div>
        )
    }

    if(loading){
        return(
            <div>
                <SkeletonTheme color="#303043" highlightColor="#3A3A4C">
                    <div className="server-list">
                        <div className="page-title">Select a Server</div>

                        <div className="list-items">
                            <div className="columns is-centered">
                                <div className="column is-7">
                                    <ServerListLoader />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
        )
    }

    return(
        <div>
            <div className="server-list">
                <div className="page-title noselect">Select a Server</div>

                <div className="list-items">
                    <SkeletonTheme color="#303043" highlightColor="#3A3A4C">
                        <div className="columns is-centered">
                            <div className="column is-7">
                                <div className="server-search">
                                    <div class="field">
                                        <p class="control has-icons-right">
                                            <input class="input" type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Filter Servers" />
                                            <span class="icon is-small is-right">
                                                <span class="material-symbols-rounded">
                                                    search
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                {searchResults.map(function(server){
                                    return (
                                        <div><ServerObject server={server} /></div>
                                    );
                                })}
                            </div>
                        </div>
                    </SkeletonTheme>
                </div>
            </div>
        </div>
    )
};

const ServerObject = ({ server }) => {
    return(
        <div>  
            <div className={`server-list-box`}>
                <div className="columns is-vcentered is-variable is-1">
                    <div className="column is-narrow">
                        <div className="server-avatar">
                            <div className="server-bubble">
                                <img alt={`${server.name} Server Icon`} src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}.png?size=128`} onError={ (e) => { e.target.src = '/images/logo.webp' }}  className="server-bubble-icon" />
                            </div>
                            <div className="server-avatar-blur" />
                            <img alt={`${server.name} Server Icon`} src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}.png?size=128`} onError={ (e) => { e.target.src = '/images/logo.webp' }}  className="server-avatar-background" />
                        </div>
                    </div>
                    <div className="column">
                        <div className="server-info">
                            <div className="server-name">
                                { server.name }
                            </div>
                            <ServerRankIndicator server={server} />
                        </div>
                    </div>
                    <div className="column is-narrow">
                        <ListButton 
                        serverId={ server.id } 
                        available={ server.available } 
                        support={server.support_session} 
                        server={server} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const ServerRankIndicator = ({ server }) => {
    
    if(server.rank === 'owner'){
        return(
            <div className="server-rank owner">
                <div className="columns is-gapless">
                    <div className="column is-narrow">
                        <span class="material-symbols-rounded">
                            admin_panel_settings
                        </span>
                    </div>
                    <div className="column">
                        <span className="server-rank-text">
                            Server Owner
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    if(server.rank === 'support'){
        return(
            <div className="server-rank support">
                <div className="columns is-gapless">
                    <div className="column is-narrow">
                        <span class="material-symbols-rounded">
                            vpn_key
                        </span>
                    </div>
                    <div className="column">
                        <span className="server-rank-text">
                            Support Session
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    if(server.rank === 'server_admin'){
        return(
            <div className="server-rank s_admin">
                <div className="columns is-gapless">
                    <div className="column is-narrow">
                        <span class="material-symbols-rounded">
                            badge
                        </span>
                    </div>
                    <div className="column">
                        <span className="server-rank-text">
                            Server Administrator
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    if(server.rank === 'bot_admin'){
        return(
            <div className="server-rank b_admin">
                <div className="columns is-gapless">
                    <div className="column is-narrow">
                        <span class="material-symbols-rounded">
                            badge
                        </span>
                    </div>
                    <div className="column">
                        <span className="server-rank-text">
                            Bot Administrator
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    return null;

};

const ListButton = (props) => {

    const history = useHistory();

    const invite = (serverId) => {
        window.open(
            'https://discord.com/oauth2/authorize?client_id=736888501026422855&scope=bot&permissions=2243292232&guild_id=' + serverId, 'InviteBot',
            "width=600,height=800,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=" + 500 + ",top=" + 200
        );

        window.addEventListener('message', function(event){
            // ...
        });
    };

    const endSupportSession = () => {
        client.post(`admin/support/end`, { support_code: props.server.support_code }).then(response => {
            history.push('/dashboard');
            window.location.reload(false);
        });
    };

    if(props.support){
        return(
            <div className="columns is-vcentered is-mobile is-gapless is-2">
                <div className="column">
                    <Link to={`/dashboard/${props.serverId}`} className="button is-primary is-configure support-session-button left">View</Link>
                </div>
                <div className="column">
                    <div className="button is-primary is-configure support-session-button right" onClick={endSupportSession}>End Session</div>
                </div>
            </div>
        )
    }

    if(props.available){
        return (
            <div className="button-wrapper">
                <Link to={`/dashboard/${props.serverId}`} className="button is-primary is-configure">Configure</Link>
            </div>
        );
    }else{
        return (
            <div className="button-wrapper">
                <button className="button is-grey is-setup" onClick={ () => invite(props.serverId) }>Setup</button>
            </div>
        )
    }

};

export default ServerList;