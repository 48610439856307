import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    guilds: []
};

const guildsSlice = createSlice({
    name: 'guildList',
    initialState,
    reducers: {
        guildsFetched(state, action) {
            state.guilds = action.payload;
        },
        updatedModuleState(state, action){
            const { guild_id, module, active } = action.payload;

            const guild = state.guilds.find(guild => guild.id === guild_id);

            guild.modules[module] = active;
        }
    }
});

export const { guildsFetched, updatedModuleState } = guildsSlice.actions;

export default guildsSlice.reducer;