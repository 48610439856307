import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { client } from "../../api/client";
import SlideToggle from "../../components/SlideToggle";
import SegmentedControl from "../../panel/components/SegmentedControl";
import toast from "react-hot-toast";

const ModuleConfiguration = (props) => {

    const [page, setPage] = useState('WELCOMER');

    const handlePageChange = (page) => {
        setPage(page);
    };

    return (
        <div>
            <SegmentedControl 
                name="admin-module-configuration"
                callback={(page) => handlePageChange(page)}
                segments={[
                    { label: 'Welcomer', value: 'WELCOMER', ref: useRef() },
                    { label: 'Levels', value: 'LEVELS', ref: useRef() },
                ]} 
                classList={"is-box-theme"}
            />

            <CSSTransition in={page === 'WELCOMER'} timeout={250} classNames="page" unmountOnExit>
                <div><Welcomer /></div>
            </CSSTransition>

            <CSSTransition in={page === 'LEVELS'} timeout={250} classNames="page"unmountOnExit>
                <div><Levels /></div>
            </CSSTransition>
        </div>
    )
};

const Welcomer = (props) => {

    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        client.get(`admin/modules/welcomer`).then(response => {
            setSettings(response.global_settings);
            setTimeout(function () {
                setLoading(false);
            }, 1000);
        });
    }, []);

    const handleChange = (setting, value) => {
        let toggler = toast.loading("Toggling module setting...");
        let sets = settings;
        sets[value] = setting;
        setSettings(sets);
        sets['module'] = 'welcomer';

        console.log(sets);

        client.post(`admin/modules`, sets).then(response => {
            setTimeout(() => {
                toast.dismiss(toggler);
                toast.success("Toggled module setting!");
            }, 1000);
        });
    };

    if (loading) {
        return (<div>
            <br /> <br />
            <center>
                <div class="dot-pulse"></div>
            </center>
        </div>)
    }

    return (
        <div>
            <div className="admin-toggle">
                <div className="columns is-vcentered is-mobile">
                    <div className="column">
                        Join Announcements
                    </div>
                    <div className="column is-narrow">
                        <SlideToggle
                            checked={settings['join_announcements']}
                            setting={'join_announcements'}
                            handle={(setting, value) => handleChange(setting, value)}
                            reset={false} />
                    </div>
                </div>
            </div>
        </div>
    )
};

const Levels = (props) => {

    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        client.get(`admin/modules/levels`).then(response => {
            setSettings(response.global_settings);
            setTimeout(function () {
                setLoading(false);
            }, 1000);
        });
    }, []);

    const handleChange = (setting, value) => {
        let toggler = toast.loading("Toggling module setting...");
        let sets = settings;
        sets[value] = setting;
        setSettings(sets);
        sets['module'] = 'levels';

        console.log(sets);

        client.post(`admin/modules`, sets).then(response => {
            setTimeout(() => {
                toast.dismiss(toggler);
                toast.success("Toggled module setting!");
            }, 1000);
        });
    };

    if (loading) {
        return (<div>
            <br /> <br />
            <center>
                <div class="dot-pulse"></div>
            </center>
        </div>)
    }

    return (
        <div>
            <div className="admin-toggle">
                <div className="columns is-vcentered is-mobile">
                    <div className="column">
                        Level Announcements
                    </div>
                    <div className="column is-narrow">
                        <SlideToggle
                            checked={settings['level_announcements']}
                            setting={'level_announcements'}
                            handle={(setting, value) => handleChange(setting, value)}
                            reset={false} />
                    </div>
                </div>
            </div>

            <div className="admin-toggle">
                <div className="columns is-vcentered is-mobile">
                    <div className="column">
                        Level Rewards
                    </div>
                    <div className="column is-narrow">
                        <SlideToggle
                            checked={settings['level_rewards']}
                            setting={'level_rewards'}
                            handle={(setting, value) => handleChange(setting, value)}
                            reset={false} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ModuleConfiguration;