const localStorageKey = '__TEMPO_TOKEN__';

export async function client(endpoint, { body, ...customConfig } = {}) {
    const headers = { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
     };
  
    const token = window.localStorage.getItem(localStorageKey);

    if(token)
        headers.Authorization = `Bearer ${token}`;

    const config = {
      method: body ? 'POST' : 'GET',
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
      mode: 'cors'
    };
  
    if (body) {
      config.body = JSON.stringify(body);
    }

    return window
        .fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config)
        .then(async response => {
            if(response.status === 401){
                return {authenticated: false};
            }
            if(response.ok){
                return await response.json();
            }else{
                const errorMessage = await response.text();
                return Promise.reject(new Error(errorMessage));
            }
        });

  
    // let data;
    // try {
    //   const response = await window.fetch(endpoint, config);
    //   data = await response.json();
    //   if (response.ok) {
    //     // Return a result object similar to Axios
    //     return {
    //       status: response.status,
    //       data,
    //       headers: response.headers,
    //       url: response.url,
    //     };
    //   }
    //   throw new Error(response.statusText);
    // } catch (err) {
    //   return Promise.reject(err.message ? err.message : data);
    // }
}
  
client.get = async function (endpoint, customConfig = {}) {
    return await client(endpoint, { ...customConfig, method: 'GET' });
}
  
client.post = async function (endpoint, body, customConfig = {}) {
    return await client(endpoint, { ...customConfig, body });
}
  