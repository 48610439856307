import { NavLink } from "react-router-dom";

const AdminSidebar = (props) => {
    return (
        <div>
            <div className="admin-sidebar">
                <NavLink exact to={ "/admin" } activeClassName={ props.loading ? '' : 'is-active' } className="admin-sidebar-link">
                    Dashboard
                </NavLink>

                <NavLink exact to={ "/admin/support" } activeClassName={ props.loading ? '' : 'is-active' } className="admin-sidebar-link">
                    Support Codes
                </NavLink>

                <NavLink exact to={ "/admin/modules" } activeClassName={ props.loading ? '' : 'is-active' } className="admin-sidebar-link">
                    Module Configuration
                </NavLink>

                <NavLink exact to={ "/admin/commands" } activeClassName={ props.loading ? '' : 'is-active' } className="admin-sidebar-link">
                    Command Configuration
                </NavLink>

                <NavLink exact to={ "/admin/notifications" } activeClassName={ props.loading ? '' : 'is-active' } className="admin-sidebar-link">
                    Notifications
                </NavLink>
            </div>

            <br />

            <div className="admin-sidebar">
                <NavLink exact to={ "/admin/chargebee" } activeClassName={ props.loading ? '' : 'is-active' } className="admin-sidebar-link">
                    Chargebee
                </NavLink>
                <NavLink exact to={ "/admin/roles" } activeClassName={ props.loading ? '' : 'is-active' } className="admin-sidebar-link">
                    Manage User Roles
                </NavLink>
            </div>
        </div>
    )
};

export default AdminSidebar;