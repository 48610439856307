import { Link } from "react-router-dom";

const Footer = () => {
    return(
        <div className="base-footer">
            <div className="container">
                
                <div className="columns">
                    <div className="column">
                        <div className="columns is-vcentered is-mobile">
                            <div className="column is-narrow">
                                <div id="navbar-logo" className="tempo-logo" />
                            </div>
                            <div className="column">
                                <div className="tempo">TEMPO</div>
                            </div>
                        </div>
                        <div className="tempo-subtext">
                            Experience the highest audio quality on lalala can be changed putting this here otherwise it never will be changed
                        </div>
                    </div>
                    <div className="column is-2">
                        <Link to="/" className="links-header">
                            Tempo
                        </Link>

                        <Link to="https://discord.gg/tempobot" className="link-item">
                            Support Server
                        </Link>
                        <Link to="/commands" className="link-item">
                            Commands
                        </Link>
                        <Link to="/dashboard" className="link-item">
                            Dashboard
                        </Link>
                        <Link to="/premium" className="link-item">
                            Premium
                        </Link>
                        <Link to="/guide" className="link-item">
                            Guide
                        </Link>
                        <Link rel="/faq" className="link-item">
                            FAQ
                        </Link>
                    </div>
                    <div className="column is-2">
                        <div className="links-header">
                            Legal
                        </div>

                        <div className="link-item">
                            Terms & Conditions
                        </div>
                        <div className="link-item">
                            Privacy Policy
                        </div>
                    </div>
                </div>

                <div className="tempo-copyright">
                    © 2021-2022 TempoBot Ltd | Company No. 13157850
                </div>
            </div>
        </div>
    )
};

export default Footer;