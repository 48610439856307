import { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tippy";
import { CSSTransition } from "react-transition-group";
import { client } from "../../api/client";
import SegmentedControl from "../../panel/components/SegmentedControl";

const Chargebee = ({ }) => {

    const [loading, setLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [search, setSearch] = useState("");

    const [section, setSection] = useState("LIST");

    useEffect(() => {
        loadSubscriptions();
    }, []);

    const loadSubscriptions = () => {
        setLoading(true);
        client.get(`admin/developer/chargebee/list`).then(response => {
            setSubscriptions(response.subscriptions);
            setTimeout(function () {
                setLoading(false);
            }, 1000);
        });
    };

    return (
        <div>
            <div className="columns is-vcentered is-mobile">
                <div className="column">
                    <div className="page-title">
                        Chargebee Subscriptions
                    </div>
                </div>
                
                <CSSTransition in={section === 'LIST'} timeout={250} classNames="page" unmountOnExit>
                    <div className="column is-narrow">
                        <input type="text" class="input search-box" placeholder="Search by user or subscription" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                </CSSTransition>

                <CSSTransition in={section === 'LIST'} timeout={250} classNames="page" unmountOnExit>
                    <div class="column is-narrow">
                        <button class="button new-subscription" onClick={() => setSection('CREATE')}>
                            New Subscription
                        </button>
                    </div>
                </CSSTransition>

                <CSSTransition in={section === 'CREATE'} timeout={250} classNames="page" unmountOnExit>
                    <div class="column is-narrow">
                        <button class="button new-subscription" onClick={() => setSection('LIST')}>
                            View Subscriptions
                        </button>
                    </div>
                </CSSTransition>
            </div>

            <CSSTransition in={section === 'LIST'} timeout={250} classNames="page" unmountOnExit>
                <ChargebeeList loading={loading} subscriptions={loading ? [] : subscriptions} search={search} loadSubscriptions={loadSubscriptions} setSection={setSection} />
            </CSSTransition>
            <CSSTransition in={section === 'CREATE'} timeout={250} classNames="page" unmountOnExit>
                <ChargebeeCreate loadSubscriptions={loadSubscriptions} setSection={setSection} />
            </CSSTransition>
        </div>
    );
};

const ChargebeeCreate = ({ loadSubscriptions, setSection }) => {

    const [userId, setUserId] = useState("");
    const [servers, setServers] = useState(1);

    const createSubscription = () => {
        client.post(`admin/developer/chargebee/create`, { user_id: userId, servers: servers }).then(response => {
            loadSubscriptions();
            setSection('LIST');
        });
    };

    return (
        <div>
            <div className="content-card configuration-section">
                <div className="configuration-section-header noselect">
                    <div className="columns is-mobile">
                        <div className="column">
                            <div className="configuration-section-title">
                                Create Subscription
                            </div>
                        </div>
                        <div className="column is-narrow">
                            <button class="button is-dark sub-button" onClick={createSubscription}>
                                Create
                            </button>
                        </div>
                    </div>
                </div>
                <div className="configuration-section-body">
                    <div className="configuration-item-title">
                        User ID
                    </div>

                    <input name="prefix" type="text" placeholder="user id" value={userId} onChange={(e) => setUserId(e.target.value)} />
                    
                    <br />

                    <div className="configuration-item-title" style={{ marginTop: '10px' }}>
                        Servers
                    </div>
                        <SegmentedControl 
                            name="create-subscription-servers"
                            callback={(servers) => setServers(servers)}
                            segments={[
                                { label: '1 Server', value: '1', ref: useRef() },
                                { label: '3 Servers', value: '3', ref: useRef() },
                                { label: '10 Servers', value: '10', ref: useRef() },
                                { label: '200 Servers', value: '200', ref: useRef() },
                            ]}
                        />
                </div>
            </div>
        </div>
    );
};

const ChargebeeList = ({ loading, subscriptions, search, loadSubscriptions, setSection }) => {

    const searchResults = subscriptions.filter(
        subscription => {
            return (
                subscription.user_id.includes(search) ||
                subscription.subscription_id.includes(search)
            );
        }  
    );

    const cancelSubscription = (subscriptionId, userId) => {
        client.post(`chargebee/cancel`, { subscription_id: subscriptionId, user_id: userId }).then(response => {
            loadSubscriptions();
            setSection('LIST');
        });
    }

    const resumeSubscription = (subscriptionId, userId) => {
        client.post(`chargebee/reactivate`, { subscription_id: subscriptionId, user_id: userId }).then(response => {
            loadSubscriptions();
            setSection('LIST');
        });
    }

    return (
        <div>
            <div className="subscription-list">
                {loading ? '' : searchResults.map(({subscription_id, user_id, created_at, servers, is_active, subscription_type}) => {
                    return (
                        <div>
                            <div className="subscription-item">
                                <div className="columns is-vcentered is-mobile">
                                    <div className="column is-narrow">
                                        <Tooltip title={'Subscription ' + (is_active ? 'Active' : 'Inactive')} arrow="true" animation="fade">
                                            <div className={"active-indicator " + (is_active ? '' : 'red')} />
                                        </Tooltip>
                                    </div>
                                    <div className="column is-narrow">
                                        <div className="sub-title">
                                            User ID
                                        </div>
                                        <div className="sub-info">
                                            {user_id}
                                        </div>
                                    </div>
                                    <div className="column is-3">
                                        <div className="sub-title">
                                            Subscription
                                        </div>
                                        <div className="sub-info">
                                            {subscription_id} &middot; {servers} server{servers === 1 ? '' : 's'}
                                        </div>
                                    </div>
                                    <div className="column is-narrow">
                                        <div className="sub-title">
                                            Created
                                        </div>
                                        <div className="sub-info">
                                            {created_at}
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="sub-title">
                                            Sub Type
                                        </div>
                                        <div className="sub-info">
                                            {subscription_type}
                                        </div>
                                    </div>
                                    <div className="column is-narrow">
                                        {is_active ? 
                                            <div>
                                                <button className="button is-dark sub-button" onClick={() => cancelSubscription(subscription_id, user_id)}>
                                                    Cancel
                                                </button>
                                            </div>
                                        :
                                            <div>
                                                <button className="button is-dark sub-button" onClick={() => resumeSubscription(subscription_id, user_id)}>
                                                    Reactivate
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )  
};


export default Chargebee;