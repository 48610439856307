import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const localStorageKey = '__TEMPO_TOKEN__';

function TempoRoute({ element, path, authenticated = false, component = null, render = null, ...props}){
    let ele = element;

    if(authenticated){
        if(window.localStorage.getItem(localStorageKey) === null)
            return (
                <div>
                    <Redirect to="/login" />
                </div>
            );
    }

    if(render === null)
        return <Route exact path={path} component={component === null ? ele : component} {...props} />;
    return <Route exact path={path} render={(props) => render} {...props} />;
};

export default TempoRoute;

