const Index = () => {
    return (
        <div>
            <div className="page-title">
                Welcome to the Admin Dashboard!
            </div>
            <div className="page-subtitle">
                This configuration section is only accessible by users with the <b>developer</b> role.

                <br />
                <br />
                To get started, click a page on the left
            </div>
        </div>
    )
};

export default Index;