import React, { useEffect, useState } from "react";

const SlideToggle = ({checked, setting, handle, reset, disabled = false}) => {

    const [defaultValue, setDefaultValue] = useState(checked);
    const [value, setValue] = useState(checked);
    const input = React.createRef();

    const toggled = (e) => {
        if (disabled)
            return;
        handle(e.target.checked, setting || '');
    };

    useEffect(() => {
        if(value === defaultValue)
            setValue(checked);
        setDefaultValue(checked);
    }, [checked]);

    useEffect(() => {
        if(reset === undefined)
            return;
        if(input.current === null)
            return;
        if(reset){
		    input.current.checked = defaultValue;
		}
	}, [reset]);
    
    return (
        <div>
            <div className="toggle-button r" id="button-3">
                <input ref={input} type="checkbox" className="checkbox" onChange={(e) => toggled(e)} onClick={e => e.stopPropagation()} defaultChecked={value ? true : false} disabled={disabled} />
                <div className="knobs"></div>
                <div className="layer"></div>
            </div> 
        </div>
    )

};

export default SlideToggle;