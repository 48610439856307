const localStorageKey = '__TEMPO_TOKEN__';

export const login = (id) => {
    const button = document.getElementById(id);
    if(button != null) button.classList.toggle('is-loading');
    
    window.open(
        process.env.REACT_APP_AUTH_URL + "login", "SignIn",
        "width=600,height=800,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=" + 500 + ",top=" + 200
    );

    window.addEventListener('message', function(event){
        // UNCOMMENT FOR PRODUCTION 
            // if(event.origin !== process.env.REACT_APP_SITE_URL)
            //     return;

        if(!((typeof event.data === 'string' || event.data instanceof String)))
            return;

        const data = JSON.parse(event.data);

        console.log(data);
        this.window.localStorage.setItem(localStorageKey, data.user.api_token);
        delete data.user.api_token;


        if(button != null) button.classList.toggle('is-loading');

       return data.user;
    });
};

export const _ROLES = {
    developer: 13,
    communitymanager: 12,
    moderator: 11,
    support: 10,
    premium: 3,
    partner: 2,
    tester: 1
};

export const _ROLENAMES = {
    developer: 'Developer',
    communitymanager: 'Community Manager',
    moderator: 'Moderator',
    support: 'Support',
    premium: 'Premium',
    partner: 'Partner',
    tester: 'Tester'
};