function ApplicationLoader(props){
    if(!props.loading)
        return null;

    return (
        <div>
            <div className="app-loader">
                <div className="columns is-vcentered is-centered is-mobile" style={{ width: '100vw', height: '100vh' }}>
                    <div className="column is-narrow">
                        <div class="t-loader triangle">
                            <svg viewBox="0 0 86 80">
                                <polygon points="43 8 79 72 7 72"></polygon>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationLoader;