import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: false
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userFetched(state, action) {
            state.user = action.payload.user;
        },
        themeChanged(state, action){
            state.user.properties.theme = action.payload;
        },
        baseThemeChanged(state, action){
            state.user.properties.base_theme = action.payload;
        },
        notificationsChanged(state, action) {
            state.user.notifications = action.payload;
        }
    }
});

export const { userFetched, themeChanged, baseThemeChanged, notificationsChanged } = userSlice.actions;

export default userSlice.reducer;