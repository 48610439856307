import { useEffect, useState } from "react";
import { act } from "react-dom/test-utils";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { client } from "../../api/client";
import { LoginButton } from "../../auth/LoginButton";
import { _ROLENAMES, _ROLES } from "../../helper/AuthHelper";
import ManagePremium from "./ManagePremium";
import { baseThemeChanged, themeChanged, userFetched } from "./userSlice";

const localStorageKey = '__TEMPO_TOKEN__';

export const UserProfile = ({ user }) => {

    const [page, setPage] = useState('profile');
    const [activeRole, setActiveRole] = useState('user'); 

    const [menuHeight, setMenuHeight] = useState('144');

    const dispatch = useDispatch();
    const history = useHistory();

    const changeTheme = (theme) => {
        if(user.properties.theme === theme)
            return;

        client.post('user/theme', { theme: theme });

        dispatch(themeChanged(theme));

        toast.success('Theme successfully changed!');
    };

    const changeBaseTheme = (theme) => {
        if(user.properties.base_theme === theme)
            return;

        client.post('user/theme/base', { theme: theme });

        dispatch(baseThemeChanged(theme));

        toast.success('Base theme successfully changed!');
    };

    useEffect(() => {
        if (!user) return;
        sortRoles();
    }, [user]);

    const logout = () => {
        window.localStorage.removeItem(localStorageKey);
        dispatch(userFetched({ user: false }));
        history.push('/');
    };

    const calcHeight = (el) => {
        const height = el.offsetHeight;
        setMenuHeight(height);
        console.log(height);
    };

    const sortRoles = () => {
        if(!user) return;

        let highestRole = -1;
        let topRole = null;
        user.roles.forEach((role) => {
            if(_ROLES[role] > highestRole){
                highestRole = _ROLES[role];
                topRole = role;
            }
        });

        if(topRole === null)
            return;

        setActiveRole(topRole);
    };

    const openManagePremium = () => {
        if (!user.roles.includes('premium'))
            return;
        document.getElementById(`manage-premium-modal`).classList.toggle('is-active');
    };
    return(
        <div>
            <CSSTransition in={ user !== false } timeout={500} classNames="profile" unmountOnExit>
                <div>
                    <div className="navbar-item has-dropdown is-hoverable profile">
                        <div className="navbar-item profile user-profile-step">
                            {/* + ( user.properties.premium ? " premium" : "" )  */}
                            <div className={ "user-profile " + activeRole }>
                                <div className="columns is-vcentered is-gapless is-mobile">
                                    <div className="column is-narrow">
                                        <div className={"user-icon-container " + activeRole}>
                                            <img alt="User Avatar" src={ user.avatar + "?size=64" } className="user-icon" />
                                            <div className="user-icon-badge">
                                                <div className={"user-role-badge " + activeRole} />
                                            </div>
                                        </div>
                                    </div>
                                    { (activeRole !== "user" || activeRole !== "") }
                                    <div className="column">
                                        <span className={"user-name " + activeRole }>
                                            { user.name }
                                        </span>
                                        <span className="user-discriminator">
                                            { (activeRole !== "user" ? _ROLENAMES[activeRole] : ("#" + user.discriminator)) }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        { user === false ? <div></div> :
                        <div className="navbar-dropdown is-boxed" style={{ height: menuHeight + 10 }}>
                            
                            <CSSTransition in={page === 'profile'} timeout={500} onEnter={calcHeight} classNames="menu-primary" unmountOnExit>
                                <div>
                                    {process.env.REACT_APP_ENV === 'dev' ? <Link to="/dashboard" className="navbar-item">Servers</Link> : <div></div> }

                                    <div className="navbar-item colors" onClick={ () => setPage("colors") }>
                                        <div className="columns is-vcentered is-mobile is-gapless" style={{ width: '100%' }}>
                                            <div className="column">
                                                Theme
                                            </div>
                                            <div className="column is-narrow">
                                                <div className="color-circle theme" />
                                            </div>
                                        </div>
                                    </div>

                                    {(user.roles.includes('premium')) ?
                                        <div className="navbar-item" onClick={() => openManagePremium()}>
                                            Manage Premium
                                        </div>
                                    : <div /> }
                                        
                                    {process.env.REACT_APP_ENV === 'dev' && (user.roles.includes('developer') || user.roles.includes('support')) ?
                                        <Link to="/admin" className="navbar-item">
                                            Admin
                                        </Link>
                                    : <div></div>}

                                    <div className="navbar-item logout" onClick={() => logout()}>
                                        Logout
                                    </div>
                                </div>
                            </CSSTransition>

                            <CSSTransition in={page === 'colors'} timeout={500} classNames="menu-secondary" onEnter={calcHeight} unmountOnExit>
                                <div>
                                    <div className="navbar-item" onClick={ () => setPage('profile') }>&#xab; Back</div>

                                    <hr className="color" />

                                    <div className={"navbar-item colors " + (user.properties.theme === 'purple' ? 'is-active' : '') } onClick={ () => changeTheme('purple') }>
                                        <div className="columns is-vcentered is-mobile is-gapless" style={{ width: '100%' }}>
                                            <div className="column">
                                                Purple
                                            </div>
                                            <div className="column is-narrow">
                                                <div className="color-circle purple" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"navbar-item colors " + (user.properties.theme === 'red' ? 'is-active' : '') } onClick={ () => changeTheme('red') }>
                                        <div className="columns is-vcentered is-mobile is-gapless" style={{ width: '100%' }}>
                                            <div className="column">
                                                Red
                                            </div>
                                            <div className="column is-narrow">
                                                <div className="color-circle red" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"navbar-item colors " + (user.properties.theme === 'blue' ? 'is-active' : '') } onClick={ () => changeTheme('blue') }>
                                        <div className="columns is-vcentered is-mobile is-gapless" style={{ width: '100%' }}>
                                            <div className="column">
                                                Blue
                                            </div>
                                            <div className="column is-narrow">
                                                <div className="color-circle blue" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"navbar-item colors " + (user.properties.theme === 'yellow' ? 'is-active' : '') } onClick={ () => changeTheme('yellow') }>
                                        <div className="columns is-vcentered is-mobile is-gapless" style={{ width: '100%' }}>
                                            <div className="column">
                                                Yellow
                                            </div>
                                            <div className="column is-narrow">
                                                <div className="color-circle yellow" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"navbar-item colors " + (user.properties.theme === 'aqua' ? 'is-active' : '') } onClick={ () => changeTheme('aqua') }>
                                        <div className="columns is-vcentered is-mobile is-gapless" style={{ width: '100%' }}>
                                            <div className="column">
                                                Aqua
                                            </div>
                                            <div className="column is-narrow">
                                                <div className="color-circle aqua" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"navbar-item colors " + (user.properties.theme === 'green' ? 'is-active' : '') } onClick={ () => changeTheme('green') }>
                                        <div className="columns is-vcentered is-mobile is-gapless" style={{ width: '100%' }}>
                                            <div className="column">
                                                Green
                                            </div>
                                            <div className="column is-narrow">
                                                <div className="color-circle green" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"navbar-item colors " + (user.properties.theme === 'blurple' ? 'is-active' : '') } onClick={ () => changeTheme('blurple') }>
                                        <div className="columns is-vcentered is-mobile is-gapless" style={{ width: '100%' }}>
                                            <div className="column">
                                                Blurple
                                            </div>
                                            <div className="column is-narrow">
                                                <div className="color-circle blurple" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CSSTransition>
                        </div> }
                    </div>  
                </div>
            </CSSTransition>

            <CSSTransition in={user === false} timeout={500} classNames="profile" unmountOnExit>
                <div className="navbar-item">
                    <div className="buttons">
                        <LoginButton classes="button text-link login-button" text="Login" />
                    </div>
                </div>
            </CSSTransition>
        </div>
    )

};