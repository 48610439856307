import PremiumPage from "../../PremiumPage";

const Premium = () => {
    return (
        <div className="module">
            <PremiumPage />
        </div>
    )
};

export default Premium;