import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider, useDispatch, useSelector} from 'react-redux';
import store from './app/store';
import { App } from './app/App';
import { client } from './app/api/client';
import { userFetched } from './app/features/user/userSlice';
import { Toaster } from 'react-hot-toast';
import ApplicationLoader from './app/components/ApplicationLoader';
import { CSSTransition } from 'react-transition-group';
import Pusher from 'pusher-js';

console.warn = () => {};

function Index() {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user).user;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        authenticate();
    }, []);

    const authenticate = () => {
        if(user !== false){
            setTimeout(function(){
                setLoading(false);
            }, 2500);
            return; // user is already stored
        }

        console.log("%c[TEMPO] %cAuthenticating user...", "color: #a173ff;", "color: #7FDBFF");

        client.get('user/authenticate').then(response => {
            if(response.authenticated){
                dispatch(userFetched(response));

                console.log("%c[TEMPO] %cSuccessfully authenticated " + response.user.name + "#" + response.user.discriminator + " (" + response.user.id + ")", "color: #a173ff;", "color: #7FDBFF");
            }else console.log("%c[TEMPO] %cUser not authenticated.", "color: #a173ff;", "color: #7FDBFF");
        });

        setTimeout(function(){
            setLoading(false);
        }, 2500);
    };

    return (
        <div>
            <CSSTransition in={loading} timeout={500} classNames="application-loader">
                <ApplicationLoader loading={ loading } />
            </CSSTransition> 

            <CSSTransition in={!loading} timeout={500} classNames="application-loader">
                <App />
            </CSSTransition>
        </div>
    )
}

ReactDOM.render(<Provider store={store}><Index /></Provider>, document.getElementById('root'));