import reactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import Navbar from "../site/layout/Navbar";
import DocSidebar from "./layout/DocSidebar";
import MarkdownRenderer from 'react-markdown-renderer';
import { Component, useEffect, useState } from "react";
import { client } from "../api/client";
import { marked } from "marked";
import Markdown from "marked-react";
import remarkGfm from "remark-gfm";
import Lowlight from 'react-lowlight';

import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
import java from 'highlight.js/lib/languages/java';
import hljs from "highlight.js";
import { act } from "react-dom/test-utils";
import { Switch, useParams, withRouter } from "react-router-dom";
import TempoRoute from "../components/TempoRoute";

Lowlight.registerLanguage('javascript', javascript);
Lowlight.registerLanguage('python', python);
Lowlight.registerLanguage('java', java);


const Docs = () => {

    const [markdown, setMarkdown] = useState('');

    const [docs, setDocs] = useState([]);
    const [activePage, setActivePage] = useState(-1);
    const [activePageData, setActivePageData] = useState({markdown: ''});

    const user = useSelector(state => state.user).user;

    useEffect(() => {
        client.get('docs').then(response => {
            setDocs(response.docs);
            // let i = 0;
            // response.docs.forEach((doc) => {
            //     if (i !== 0)
            //         return;
            //     if (doc.pages.length > 0) {
            //         setActivePage(doc.pages[0].id);
            //         return;
            //     }
            //     i++;
            // });

        // client.get('markdown').then(response => {
        //     setMarkdown(response.markdown);
        });
        hljs.highlightAll();
    }, []);

    useEffect(() => {
        docs.forEach((doc) => {
            doc.pages.forEach((page) => {
                if (page.id === activePage)
                    setActivePageData(page);
            });
        });
    }, [activePage]);

    useEffect(() => {
        console.log('DOCS', docs);
    }, [docs]);

    return (
        <div>
            <Navbar />
            <div className="columns is-gapless">
                <div className="column is-narrow">
                    <DocSidebar setDocs={setDocs} user={user} docs={docs} activePage={activePage} setActivePage={setActivePage} />
                </div>
                <div className="column">
                    <div className="page-container">
                        <div id="sidebar-blur" className="sidebar-blur" />
                        <div className="module">
                            <div className="container markdown-body">
                                <Switch>
                                    <TempoRoute exact path="/docs/article/:id" component={withRouter(DocPage)} />
                                    <TempoRoute exact path="/docs" component={withRouter(DocHome)} />
                                </Switch>
                                {/* <Markdown renderer={renderer}>
                                    {String(activePageData.markdown)}
                                </Markdown>  */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const DocHome = () => {
    return (<div>
        welcome to docs!
    </div>)
};

const DocPage = () => {

    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState({});

    const renderer = {
        code: (snippet, lang) => {
            return <div><Lowlight language={lang} value={snippet} /></div>;
        },
    };

    useEffect(() => {
        console.log('doc!');
        client.get(`docs/${id}`).then(response => {
            setPageData(response.page);
            setTimeout(function () {
                setLoading(false);
            }, 100);
        });
    }, []);

    if (loading)
        return (
            <div>
                loading...
            </div>
        )
    
    return (
        <div>
            <Markdown renderer={renderer}>
                {String(pageData.markdown)}
            </Markdown>
        </div>
    )
};

export default Docs;
