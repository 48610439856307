import { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tippy";
import { CSSTransition } from "react-transition-group";
import { client } from "../../api/client";
import SegmentedControl from "../../panel/components/SegmentedControl";
import Select from "react-select";

const Roles = () => {

    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [results, setResults] = useState([]);

    const [saving, setSaving] = useState(-1);

    useEffect(() => {
        if(search === "")
            return;
        client.post(`admin/developer/roles`, {search: search}).then(response => {
            setResults(response.results);
        });
    }, [search]);

    return (
        <div>
            <div className="columns is-vcentered is-mobile">
                <div className="column">
                    <div className="page-title">
                        Manage User Roles
                    </div>
                </div>
                
                <div className="column is-narrow">
                    <input type="text" class="input search-box" placeholder="Search by user id or name" value={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>


            {results.length === 0 ?
                <div>
                    <h1>Search in the box above for results</h1>
                </div> :
                <div>
                    <div className="subscription-list">
                        {results.map((result) => {
                            return(
                                <div key={result.id}>
                                    <UserRole result={result} />
                                </div>
                            )
                        })}

                    </div>
                </div>
            }
        </div>
    );
};

const UserRole = ({result}) => {

    const [value, setValue] = useState({value: result.roles[0], label: result.roles[0]});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('result', result);
    }, []);

    const update = () => {
        setLoading(true);

        let role = value.value;

        client.post(`admin/developer/roles/set`, {user_id: result.id, role: role}).then(response => {
            if(!response.changed){
                setValue({value: result.roles[0], label: result.roles[0]});
                setLoading(false);
                return;
            }

            setTimeout(() => {
                setLoading(false);
            }, 1500);
        });
    };

    return(
        <div>
            <div className="subscription-item">
                <div className="columns is-vcentered">
                    <div className="column">
                        <div className="sub-title">
                            {result.name}
                        </div>
                        <div className="sub-info">
                            {result.id}
                        </div>
                    </div>
                    <div className="column is-narrow">
                        <Select
                            options={[
                                { value: 'developer', label: 'developer' },
                                { value: 'moderator', label: 'moderator' },
                                { value: 'support', label: 'support' },
                                { value: 'tester', label: 'tester' },
                                { value: 'user', label: 'user' }
                            ]}
                            isLoading={loading}
                            onChange={(value) => setValue(value)}
                            value={value}
                            className="react-select-container"
                            classNamePrefix="react-select"
                        ></Select>
                    </div>
                    <div className="column is-narrow">
                        <div className="button is-primary" onClick={() => update()}>UPDATE</div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default Roles;