import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { client } from "../../api/client";

const SupportCode = ({ }) => {

    const [code, setCode] = useState('');

    const user = useSelector(state => state.user).user;

    const history = useHistory();

    const redeemCode = () => {
        client.post(`admin/support/redeem`, { support_code: code, user_id: user.id }).then(response => {
            history.push(`/dashboard`);
        });
    };
    
    return (
        <div>
            <div className="content-card configuration-section">
                <div className="configuration-section-header noselect">
                    <div className="configuration-section-title">
                        Redeem Support Code
                    </div>
                    <div className="configuration-section-description">
                        Redeem a support code given by a user in the support server for 6 hours of read-only access to their dashboard.
                    </div>
                </div>
                <div className="configuration-section-body">
                    <div className="columns is-vcentered is-mobile is-variable is-2">
                        <div className="column">
                            <input type="text" class="input" value={code} onChange={(e) => setCode(e.target.value)} />
                        </div>
                        <div className="column is-narrow">
                            <button className="button is-config" onClick={redeemCode}>Redeem</button>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    )
};

export default SupportCode;