import {Link} from "react-router-dom";

const notFound = () => {
    return(
        <div className="notfound-page">
            <style>
                .torch{`{
                    display: block !important;
                `}
            </style>
            <div className="columns is-vcentered">
                <div className="column is-9">
                    <div className="error">
                        404 Page not found
                    </div>
                    <div className="error-small">We couldn't find what you were looking for!</div>
                    <br />

                    <div className="button-container">
                        <Link to={"/"} className="button is-primary lights-on">
                            Back Home
                            <span className="material-symbols-rounded">arrow_forward</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default notFound;