import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { client } from "./api/client";
import { themeChanged, userFetched } from "./features/user/userSlice";
import ApplicationLoader from "./components/ApplicationLoader";
import Panel from "./panel/Panel";
import Site from "./site/Site";
import toast, { Toaster, useToasterStore } from "react-hot-toast";
import ActiveModuleModal from "./panel/components/ActivateModuleModal";
import Admin from "./admin/Admin";
import Docs from "./docs/Docs";
import Footer from "./site/layout/Footer";
import ManagePremium from "./features/user/ManagePremium";
import notFound from "./site/pages/NotFound";
import ScrollToTop from "./components/ScrollToTop";

export const App = () => {

    const [loading, setLoading] = useState(true);

    const user = useSelector(state => state.user).user;

    useEffect(() => {
        setTimeout(function(){
            setLoading(false);
        }, 500);
    }, []);
    

    // Limit Toasts
    const { toasts } = useToasterStore();
    const TOAST_LIMIT = 4;
    useEffect(() => {
        toasts
            .filter((t) => t.visible) 
            .filter((_, i) => i >= TOAST_LIMIT) 
            .forEach((t) => toast.dismiss(t.id));
    }, [toasts]);

    // window.HTMLElement.prototype.scrollIntoView = function() {};

    return(
        <div>
            <div className={'page-root ' + (user === false ? 'theme-purple' : `theme-${ user.properties.theme }`)}>
                <ManagePremium premium={user !== false ? user.roles.includes('premium') : false} />

                <Toaster
                    position="bottom-right" 
                    reverseOrder={false} 
                    toastOptions={{ className: 'tempo-toaster', duration: 4000 }}/>
 

                <TempoRoutes />
            </div>
        </div>
    );

};
const TempoRoutes = () => {
    if(process.env.REACT_APP_ENV === 'dev'){
        return(
            <BrowserRouter>
                <ScrollToTop>
                    <Switch>
                        <Route path="/admin" component={Admin} />
                        <Route path="/docs" component={Docs} />
                        <Route path="/dashboard/:guild_id" component={Panel} />
                        <Route path="/" component={Site} />
                    </Switch>
                </ScrollToTop>
            </BrowserRouter>
        );
    }else{
        return(
            <BrowserRouter>
                <ScrollToTop>
                    <Switch>
                        <Route path="/admin" component={Admin} />
                        <Route path="/" component={Site} />
                    </Switch>
                </ScrollToTop>
            </BrowserRouter>
        )
    }
};