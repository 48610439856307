import { useEffect } from "react";

import Embed from '../../../images/tempo-play-embed.svg';
import Dashboard from '../../../images/tempo-dashboard.svg';
import Star from '../../../images/star.svg';
import {Link} from "react-router-dom";

export const Home = () => {

    useEffect(() => {

    });

    return (
        <div>
            <div className="container">
                <div className="columns is-vcentered is-centered home-header"
                     style={{height: 'calc(100vh - 93px)', width: '100%'}}>
                    <div className="column is-6">
                        <div className="big-tempoline">
                            Boost your servers<br/>atmosphere with <span>Tempo</span>
                        </div>
                        <div className="small-tagline">
                            Experience the highest audio quality on lalala can be changed putting this here otherwise it
                            never will be changed
                        </div>

                        <a href="https://tempobot.net/invite" className="button is-primary get-started">
                            Get Started
                            <span className="material-symbols-rounded">arrow_forward</span>
                        </a>

                    </div>
                    <div className="column is-6">
                        <img src={'/images/tempo_banner_desc_circles.png'} className={"mobile-image"} />
                        <div className="bubble-container">
                            <img src="/images/logo.png" className="bubble-main tempo-one"/>
                            <img src="/images/tempo_2.webp" className="bubble tempo-two"/>
                            <img src="/images/tempo_3.webp" className="bubble tempo-three"/>
                            <img src="/images/tempo_4.webp" className="bubble tempo-four"/>
                            <img src="/images/tempo_5.webp" className="bubble tempo-five"/>
                            <img src="/images/tempo_6.webp" className="bubble tempo-six"/>
                        </div>
                    </div>
                </div>

                <div className="image-feature">
                    <div className="columns is-centered is-vcentered">
                        <div className="column is-4">
                            <div className="feature-title">
                                Simple music
                            </div>
                            <div className="feature-description">
                                Tempo has been designed from the ground up to be simple and fast. Have complete control
                                over your music with our intuitive now playing menu
                            </div>

                            <Link to="/commands" className="button is-primary get-started feature-button">
                                Commands
                                <span className="material-symbols-rounded">arrow_forward</span>
                            </Link>

                        </div>
                        <div className="column is-5">
                            <img src={Embed} alt="Music Player" className="svg-img"></img>
                        </div>
                    </div>
                </div>

                <div className="image-feature">
                    <div className="columns is-centered is-vcentered mobile-reverse">
                        <div className="column is-5">
                            <img src={Dashboard} alt="Web Dashboard" className="svg-img"></img>
                        </div>
                        <div className="column is-4">
                            <div className="coming-soon">COMING SOON</div>
                            <div className="feature-title">
                                Web Dashboard
                            </div>
                            <div className="feature-description">
                                Break away from messy hard to use settings menus with our fully-featured web configuration dashboard. Coming soon!
                            </div>

                            {/* <div className="button is-primary get-started feature-button">
                            Get Started
                            <span class="material-symbols-rounded">arrow_forward</span>
                        </div> */}
                        </div>

                    </div>
                </div>
            </div>

            <div className="section edge-box">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column">
                            Join <b>700K other servers</b> and start listening now!
                        </div>
                        <div className="column is-narrow">
                            <a className="button is-primary get-started feature-button" href="https://tempobot.net/invite">
                                Invite Now
                                <span className="material-symbols-rounded">arrow_forward</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section is-premium">
                <div className="columns is-centered is-mobile">
                    <div className="column is-narrow">
                        <div className="star-container">
                            <img src={Star} className={"left"} style={{width:'64px'}} />
                            <div className="premium-title">Get the ultimate experience with <b>Tempo Premium</b></div>
                            <img src={Star} className={"right"} style={{width:'64px'}} />
                        </div>
                    </div>
                </div>


                <div className="columns is-centered">
                    <div className="column is-narrow">
                        <PremiumInfo
                            title={"Take control of your<br/>music"}
                            description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                            icon={"tune"} />
                    </div>
                    <div className="column is-narrow">
                        <PremiumInfo
                            title={"Sing along with your favourite songs"}
                            description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                            icon={"lyrics"} />
                    </div>
                    <div className="column is-narrow">
                        <PremiumInfo
                            title={"Get issues resolved quicker with our Premium support"}
                            description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                            icon={"support_agent"} />
                    </div>
                </div>
                <div className="columns is-centered">
                    <div className="column is-narrow">
                        <PremiumInfo
                            title={"6 additional bots for more tunes"}
                            description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                            icon={"group_add"} />
                    </div>
                    <div className="column is-narrow">
                        <PremiumInfo
                            title={"Keep the party going with 24/7 playback"}
                            description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                            icon={"more_time"} />
                    </div>
                    <div className="column is-narrow">
                        <PremiumInfo
                            title={"Find new music with autoplay suggestions"}
                            description={"Aliquip culpa ut exercitation duis minim velit irure consectetur reprehenderit eu aute excepteur. Laboris enim culpa ullamco laborum quis consequat velit reprehenderit elit."}
                            icon={"dynamic_form"} />
                    </div>
                </div>
            </div>

            <center>
                <Link to="/premium" className="button get-started premium-button">
                    Find out more
                    <span className="material-symbols-rounded">arrow_forward</span>
                </Link>
            </center>

        </div>
    );
};

const PremiumInfo = ({title, description, icon}) => {
    return(
        <div className="premium-info-box">
            <div className="columns is-centered is-mobile is-vcentered is-variable is-2">
                <div className="column is-narrow">
                    <div className="icon-box">
                        <span className="material-symbols-rounded">
                            {icon}
                        </span>
                    </div>
                </div>
                <div className="column">
                    <div className="info-title" dangerouslySetInnerHTML={{__html:title}}></div>
                </div>
            </div>
            <div className="info-description" dangerouslySetInnerHTML={{__html:description}}></div>
        </div>
    )
};