import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    player: false
};

const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        playerFetched(state, action){
            state.player = action.payload.player;
        }
    }
});

export const { playerFetched } = playerSlice.actions;

export default playerSlice.reducer;