import { configureStore } from '@reduxjs/toolkit';
import emojisSlice from './features/emoji/emojisSlice';
import guildsSlice from './features/guild/guildsSlice';
import playerSlice from './features/player/playerSlice';
import userSlice from './features/user/userSlice';

export default configureStore({
    reducer: {
        user: userSlice,
        guildList: guildsSlice,
        emojiList: emojisSlice,
        player: playerSlice
    },
});
