import { ContentState, EditorState } from "draft-js";
import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { client } from "../../api/client";
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import Editor from '@draft-js-plugins/editor';
import { useDispatch } from "react-redux";
import { notificationsChanged } from "../../features/user/userSlice";

const Notifications = ({ }) => {

    const [page, setPage] = useState('LIST');
    const [editing, setEditing] = useState([]);

    const handlePageChange = () => {
        if (page === 'LIST')
            setPage('CREATE');
        else setPage('LIST');
    };

    return (
        <div>
            <div className="columns">
                <div className="column">
                    <div className="page-title">
                        Notifications
                    </div>
                </div>
                <div className="column is-narrow">
                    <div className="button is-primary" onClick={handlePageChange}>{page === 'LIST' ? 'New Notification' : 'View Notifications'}</div>
                </div>
            </div>

            <CSSTransition in={page === 'LIST'} timeout={250} classNames="page" unmountOnExit>
                <div>
                    <NotificationList setEditing={setEditing} setPage={setPage} />
                </div>
            </CSSTransition>

            <CSSTransition in={page === 'CREATE'} timeout={250} classNames="page" unmountOnExit>
                <div>
                    <CreateNotification setPage={setPage} />
                </div>
            </CSSTransition>

            <CSSTransition in={page === 'EDIT'} timeout={250} classNames="page" unmountOnExit>
                <div>
                    <EditNotification notification={editing} setPage={setPage} />
                </div>
            </CSSTransition>
        </div>
    )

};

const NotificationList = ({ setEditing, setPage }) => {

    const [notifications, setNotifications] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        client.get(`admin/notifications`).then(response => {
            setNotifications(response.notifications);
            dispatch(notificationsChanged(response.notifications));
        });
    }, []);

    const editNotification = (notification) => {
        setEditing(notification);
        setPage('EDIT');
    };

    const deleteNotification = (id) => {
        client.post(`admin/notifications/delete`, { notification_id: id }).then(response => {
            setNotifications(response.notifications);
            setPage('LIST');
            setEditing(null);
        });
    };

    if (notifications === []) {
        return (
            <div>
                <div className="content-card">
                    <center>
                        There are no notifications
                    </center>
                </div>
            </div>
        )
    }

    return (
        <div>
            {notifications.map((notification) => {
                return (
                    <div>
                        <div className="content-card notification-admin" style={{ padding: '10px 20px' }}>
                            <div className="columns is-vcentered is-variable is-1">
                                <div className="column">
                                    <div className="notification-title">
                                        {notification.title}
                                    </div>
                                </div>
                                <div className="column is-narrow">
                                    <div className="button is-primary" onClick={() => editNotification(notification)}>Edit</div>
                                </div>
                                <div className="column is-narrow">
                                    <div className="button is-primary" onClick={() => deleteNotification(notification.id)}>Delete</div>
                                </div>
                            </div>
                        </div>
                </div>
                ) 
            })}
        </div>
    )
};

const CreateNotification = ({ setPage }) => {

    const [content, setContent] = useState(EditorState.createEmpty());
    const [title, setTitle] = useState('');
    const toolbar = createToolbarPlugin();

    const createNotification = () => {
        client.post(`admin/notifications/create`, {
            content: content.getCurrentContent().getPlainText(),
            title: title
        }).then(response => {
            setPage('LIST');
        });
    };

    return (
        <div>
            <div className="content-card configuration-section">
                <div className="configuration-section-body">
                    <div className="configuration-item-title" style={{ marginTop: '-15px' }}>
                        Notification Title
                    </div>
                    <input type="text" class="input" defaultValue={title} onChange={(e) => setTitle(e.target.value)} />

                    <br />
                    <div className="configuration-item-title" style={{ marginTop: '10px' }}>
                        Notification Description
                    </div>

                    <div className="notification-editor">
                        <Editor editorState={content} onChange={(state) => setContent(state)} plugins={[toolbar]} />
                    </div>

                    <br />
                    <div className="button is-primary" onClick={createNotification}>
                        Create Notification
                    </div>
                </div>
            </div>
        </div>
    )
};

const EditNotification = ({ notification, setPage }) => {
    const [content, setContent] = useState(EditorState.createWithContent(ContentState.createFromText(notification.content)));
    const [title, setTitle] = useState(notification.title);
    const toolbar = createToolbarPlugin();

    const editNotification = () => {
        client.post(`admin/notifications/edit`, {
            notification_id: notification.id,
            content: content.getCurrentContent().getPlainText(),
            title: title
        }).then(response => {
            setPage('LIST');
        });
    };

    return (
        <div>
            <div className="content-card configuration-section">
                <div className="configuration-section-body">
                    <div className="configuration-item-title" style={{ marginTop: '-15px' }}>
                        Notification Title
                    </div>
                    <input type="text" class="input" defaultValue={title} onChange={(e) => setTitle(e.target.value)} />

                    <br />
                    <div className="configuration-item-title" style={{ marginTop: '10px' }}>
                        Notification Description
                    </div>

                    <div className="notification-editor">
                        <Editor editorState={content} onChange={(state) => setContent(state)} plugins={[toolbar]} />
                    </div>

                    <br />
                    <div className="button is-primary" onClick={editNotification}>
                        Edit Notification
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Notifications;