import { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import SegmentedControl from "../../panel/components/SegmentedControl";

const ManagePremium = () => {

    const [page, setPage] = useState('SERVERS');

    const servers = useRef();
    const bots = useRef();

    const close = () => {
        document.getElementById(`manage-premium-modal`).classList.toggle('is-active');
    };

    return (
        <div>
            <div id="manage-premium-modal" class="modal modal-fx-fadeInScale">
                <div class="modal-background" onClick={() => close()} />
                <div class="modal-content">
                    <div className="content-card">
                        <div className="columns is-mobile">
                            <div className="column">
                                <div className="modal-title">
                                    Manage Premium
                                </div>
                            </div>
                            <div className="column is-narrow">
                                <button style={{ marginTop: '-4px' }} class="modal-close is-large" aria-label="close" onClick={() => close()}></button>
                            </div>
                        </div>

                        <SegmentedControl
                            name="manage-premium-control"
                            callback={(page) => setPage(page)}
                            segments={[
                                { label: 'Servers', value: 'SERVERS', ref:servers },
                                { label: 'Extra Bots', value: 'BOTS', ref: bots }
                            ]} />
                        
                        <CSSTransition in={page === 'SERVERS'} timeout={250} classNames="page" unmountOnExit>
                            <div>
                                uwu
                            </div>
                        </CSSTransition>
                        <CSSTransition in={page === 'BOTS'} timeout={250} classNames="page" unmountOnExit>
                            <div>
                                uwu 2
                            </div>
                        </CSSTransition>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ManagePremium;