import { useRef, useState, useEffect } from 'react';

const SegmentedControl = ({ name, segments, callback, reset, defaultIndex = 0, classList = "", disabled = false }) => {

    const [activeIndex, setActiveIndex] = useState(defaultIndex);

    const onInputChange = (value, index) => {
        if (disabled)
            return;
        setActiveIndex(index);
        callback(name, value, index);
    };

    useEffect(() => {
        if (reset) {
            setActiveIndex(defaultIndex);
        }
    }, [reset]);

    useEffect(() => {
        const activeSegmentRef = segments[activeIndex].ref;
        const { offsetWidth, offsetLeft } = activeSegmentRef.current;
        const { style } = document.documentElement;

        style.setProperty('--highlight-width', `${offsetWidth}px`);
        style.setProperty('--highlight-x-pos', `${offsetLeft}px`);
    }, [activeIndex, callback, segments]);

    return (
        <div className="segmented-control-container">
            <div className={"segmented-control " + classList} key={name}>
                {segments.map((item, i) => (
                    <div
                        key={item.value}
                        className={ `segment ${name} ${i === activeIndex ? 'active' : 'inactive'} ${disabled ? 'disabled' : ''}` }
                        ref={item.ref}
                    >
                        <input 
                            type="radio"
                            value={item.value}
                            id={item.label}
                            name={name}
                            onChange={() => onInputChange(item.value, i)}
                            checked={i === activeIndex} />
                        <label htmlFor={item.label}>
                            {item.label}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SegmentedControl;