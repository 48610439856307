import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { CSSTransition } from "react-transition-group";
import { client } from "../../api/client";
import SlideToggle from "../../components/SlideToggle";
import SegmentedControl from "../../panel/components/SegmentedControl";

const CommandConfiguration = (props) => {

    const [page, setPage] = useState('WELCOMER');

    const handlePageChange = (page) => {
        setPage(page);
    };

    return (
        <div>
            <SegmentedControl 
                name="admin-command-configuration"
                callback={(page) => handlePageChange(page)}
                segments={[
                    { label: 'Welcomer', value: 'WELCOMER', ref: useRef() }
                ]} 
                classList={"is-box-theme"}
            />

            <CSSTransition in={page === 'WELCOMER'} timeout={250} classNames="page" unmountOnExit>
                <div><Welcomer /></div>
            </CSSTransition>
        </div>
    )
};

const Welcomer = (props) => {

    const [loading, setLoading] = useState(true);
    const [commands, setCommands] = useState([]);

    useEffect(() => {
        client.get(`admin/modules/welcomer`).then(response => {
            setCommands(response.commands);
            setTimeout(function () {
                setLoading(false);
            }, 1000);
        });
    }, []);

    const handleChange = (setting, value) => {
        let toggler = toast.loading("Toggling command...");
        
        let cmds = { ...commands, [value]: !setting };
        setCommands(cmds);

        let body = { ...cmds, module: 'welcomer' };

        console.log(cmds, body);

        client.post(`admin/modules/commands`, body).then(response => {
            setTimeout(() => {
                toast.dismiss(toggler);
                toast.success("Toggled command!");
            }, 1000);
        });
    };

    if (loading) {
        return (<div>
            <br /> <br />
            <center>
                <div class="dot-pulse"></div>
            </center>
        </div>)
    }

    return (
        <div>
            {Object.keys(commands).map((key) => (
                <div>
                    <div className="admin-toggle">
                        <div className="columns is-vcentered is-mobile">
                            <div className="column">
                                >{key}
                            </div>
                            <div className="column is-narrow">
                                <SlideToggle
                                    checked={commands[key] === 0 ? true : false}
                                    setting={key}
                                    handle={(setting, value) => handleChange(setting, value)}
                                    reset={false} />
                            </div>
                        </div>
                    </div>
                </div>  
            ))}
        </div>
    )

};

export default CommandConfiguration;