import { Switch } from "react-router-dom";
import TempoRoute from "../components/TempoRoute";
import Navbar from "../panel/layout/Navbar";
import AdminNavbar from "./layout/AdminNavbar";
import AdminSidebar from "./layout/AdminSidebar";
import Chargebee from "./pages/Chargebee";
import CommandConfiguration from "./pages/CommandConfiguration";
import Index from "./pages/Index";
import ModuleConfiguration from "./pages/ModuleConfiguration";
import Notifications from "./pages/Notifications";
import SupportCode from "./pages/SupportCode";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import TempoAdminRoute from "../components/TempoAdminRoute";
import Roles from "./pages/Roles";

const Admin = () => {

    const user = useSelector(state => state.user).user;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(user !== false){
            setLoading(false);
        }
    }, [user]);

    if(loading)
        return null;

    return (
        <div>
            <div>
                {/* Page Scaffolding */}
                <div className="columns is-gapless">
                    <div className="column">
                        <AdminNavbar />

                        <div className="page-container">
                            <div id="sidebar-blur" className="sidebar-blur" />
                            <div className="module">
                                <div className="container">
                                    <div className="columns">
                                        <div className="column is-narrow">
                                            <AdminSidebar />
                                        </div>
                                        <div className="column">
                                            <Page user={user} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};

const Page = (props) => {
    return (
        <div>
            <div className="container base">
                <Switch>
                    <TempoAdminRoute exact path="/admin" component={Index} roles={props.user.roles} />
                    <TempoAdminRoute exact path="/admin/support" component={SupportCode} roles={props.user.roles} />
                    <TempoAdminRoute exact path="/admin/modules" component={ModuleConfiguration} roles={props.user.roles} />
                    <TempoAdminRoute exact path="/admin/commands" component={CommandConfiguration} roles={props.user.roles} />
                    <TempoAdminRoute exact path="/admin/notifications" component={Notifications} roles={props.user.roles} />
                    <TempoAdminRoute exact path="/admin/chargebee" component={Chargebee} roles={props.user.roles} />
                    <TempoAdminRoute exact path="/admin/roles" component={Roles} roles={props.user.roles} />
                </Switch>
            </div>
        </div>
    )
};

export default Admin;