import { CSSTransition } from "react-transition-group";
import TempoRoute from "../components/TempoRoute";
import Navbar from "./layout/Navbar";
import { Home } from "./pages/Home";
import ServerList from "./pages/ServerList";

import "../../scss/base/_site.scss";
import TestChargbee from "./pages/TestChargebee";
import Premium from "./pages/Premium";
import Commands from "./pages/Commands";
import Footer from "./layout/Footer";
import {Route, Switch} from "react-router-dom";
import notFound from "./pages/NotFound";
import {Fragment, useEffect, useState} from "react";
import ScrollToTop from "../components/ScrollToTop";

const routes = [
    { path: '/', name: 'Home', Component: Home, authenticated: false },
    { path: '/test', name: 'Chargebee', Component: TestChargbee, authenticated: false },

    { path: '/commands', name: 'Commands', Component: Commands, authenticated: false},
    { path: '/premium', name: 'Premium', Component: Premium, authenticated: false},

    { path: '/dashboard', name: 'Server List', Component: ServerList, authenticated: false }
];

const Site = () => {

    useEffect(() => {

    }, []);

    return(
        <div className="site-container" id={"page"}>
            <Navbar />

            <div className="base site" style={{paddingTop:'93px'}}>
                <Fragment>
                <Switch>
                    {routes.map(({ path, Component, authenticated }) => (
                        <TempoRoute key={path} exact path={path} authenticated={authenticated}>
                            {({ match }) => (
                                <CSSTransition
                                in={match != null}
                                timeout={300}
                                classNames="page"
                                unmountOnExit>

                                <div className="page">
                                    <Component />
                                </div>
                                </CSSTransition>
                            )}
                        </TempoRoute>
                    ))}
                    <Route component={notFound} />
                </Switch>
                </Fragment>
            </div>

            <Footer />
        </div>
    )
};

export default Site;